var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "container",
      staticStyle: { position: "relative" },
      attrs: { fluid: "" },
    },
    [
      _c("canvas", {
        staticStyle: {
          position: "absolute",
          top: "0",
          left: "0",
          "pointer-events": "none",
          "z-index": "1",
        },
        attrs: { id: "overlay", width: "600", height: "600" },
      }),
      _c("canvas", {
        staticClass: "chart",
        staticStyle: { top: "0", left: "0", "z-index": "2" },
        attrs: { id: "chart", width: "600", height: "600" },
      }),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize",
              attrs: { small: "", text: "" },
              on: {
                click: function ($event) {
                  _vm.showInfo = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { small: "", left: "" } }, [
                _vm._v("mdi-camera-control"),
              ]),
              _vm._v(" Controls "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize ml-2",
              attrs: { small: "", text: "" },
              on: { click: _vm.resetZoom },
            },
            [
              _c("v-icon", { attrs: { small: "", left: "" } }, [
                _vm._v("mdi-magnify-minus-outline"),
              ]),
              _vm._v(" Reset Zoom "),
            ],
            1
          ),
        ],
        1
      ),
      _c("ChartInfo", {
        attrs: { value: _vm.showInfo },
        on: {
          "update:value": function ($event) {
            _vm.showInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }