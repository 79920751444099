const chartData = {
  changed: false,
  type: 'bar',
  labels: [],
  datasets: [
    {
      label: 'Retail Quantity Sold',
      type: 'bar',
      data: [],
      backgroundColor: 'rgb(56, 95, 115, 0.75)',
      borderColor: 'rgb(56, 95, 115)',
      borderRadius: 4,
      borderWidth: 2,
      yAxisID: 'y2'
    },
    {
      label: 'Retail Dollars Sold',
      type: 'bar',
      data: [],
      backgroundColor: 'rgb(144, 164, 174, 0.75)',
      borderColor: 'rgb(144 164 174)',
      borderRadius: 4,
      borderWidth: 2,
      yAxisID: 'y3'
    },
    {
      label: 'Average Price',
      type: 'line',
      data: [],
      backgroundColor: 'rgb(255, 143, 0, 0.75)',
      borderColor: 'rgb(255 143 0)',
      borderWidth: 1,
      yAxisID: 'y'
    }
  ]
}

export default chartData
