var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, width: _vm.width, persistent: "" } },
    [
      _c(
        "v-card",
        { staticClass: "fill", staticStyle: { overflow: "hidden !important" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "font-weight-light pr-3 text-center",
              attrs: { color: "#666666" },
            },
            [_vm._v(" Price Point Sales Analysis | Suggested Ordering ")]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "text-center pb-0",
              staticStyle: {
                height: "600px",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _vm.loading
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm.loading
                          ? _c("div", { staticClass: "loader" }, [
                              _c("div", { staticClass: "dot dot1" }),
                              _c("div", { staticClass: "dot dot2" }),
                              _c("div", { staticClass: "dot dot3" }),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                : [
                    _c("BarChart", {
                      attrs: {
                        chartData: _vm.chartData,
                        chartOptions: _vm.chartOptions,
                        selected: _vm.selected,
                      },
                      on: {
                        setDates: _vm.setSuggestedOrderDates,
                        resetChart: _vm.resetChart,
                        cancelChart: _vm.cancelChart,
                      },
                    }),
                  ],
            ],
            2
          ),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "fill" } },
            [
              !_vm.loading
                ? [
                    _vm.dateRange.length > 1
                      ? _c(
                          "v-toolbar-title",
                          {
                            staticClass:
                              "text-subtitle-1 font-weight-bold ml-3",
                          },
                          [
                            _vm._v(" Selected Dates: "),
                            _c("span", { staticClass: "font-weight-medium" }, [
                              _vm._v(
                                " " + _vm._s(this.formattedDateRange) + " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: { width: "100" },
                        on: { click: _vm.cancelChart },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-2 text-capitalize font-weight-medium",
                        attrs: {
                          disabled: _vm.readonly || !_vm.datesSelected,
                          width: "100",
                          color: "primary",
                        },
                        on: { click: _vm.confirmUpdate },
                      },
                      [_vm._v(" Update ")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }