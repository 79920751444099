<template>
  <v-dialog :value="value" :width="width" persistent>
    <v-card class="fill" style="overflow: hidden !important">
      <v-card-title color="#666666" class="font-weight-light pr-3 text-center">
        Price Point Sales Analysis | Suggested Ordering
      </v-card-title>
      <v-card-text
        style="height: 600px; display: flex; justify-content: center"
        class="text-center pb-0">
        <template v-if="loading">
          <div style="display: flex; align-items: center">
            <div v-if="loading" class="loader">
              <div class="dot dot1"></div>
              <div class="dot dot2"></div>
              <div class="dot dot3"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <BarChart
            :chartData="chartData"
            :chartOptions="chartOptions"
            :selected="selected"
            @setDates="setSuggestedOrderDates"
            @resetChart="resetChart"
            @cancelChart="cancelChart" />
        </template>
      </v-card-text>
      <v-toolbar flat color="fill">
        <template v-if="!loading">
          <v-toolbar-title
            v-if="dateRange.length > 1"
            class="text-subtitle-1 font-weight-bold ml-3">
            Selected Dates:
            <span class="font-weight-medium">
              {{ this.formattedDateRange }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn width="100" class="text-capitalize" @click="cancelChart">
            Cancel
          </v-btn>

          <v-btn
            :disabled="readonly || !datesSelected"
            width="100"
            class="ma-2 text-capitalize font-weight-medium"
            color="primary"
            @click="confirmUpdate">
            Update
          </v-btn>
        </template>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import OrderGrid from '@/axios/order-grid'

import chartData from '@/charts/chart-data'
import chartOptions from '@/charts/chart-options'
import { addMonths, format, isBefore, subWeeks } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export default {
  name: 'ChartModal',
  props: {
    value: Boolean,
    ad: Object,
    item: Object,
    selected: Array,
    store: Object,
    readonly: Boolean
  },
  components: {
    BarChart
  },
  data() {
    return {
      avg_price_point: [],
      total_sales: [],
      total_quantity: [],
      transaction_dates: [],
      barName: 'Bar Chart',
      chartType: 'bar',
      chartLabels: [],
      confirmOrder: false,
      dateRange: [],
      chart: {},
      chartData: chartData,
      chartOptions: chartOptions,
      width: 0,
      objArr: [],
      showDialog: false,
      showLoader: false,
      loading: true
    }
  },
  computed: {
    datesSelected() {
      return this.dateRange.length === 2
    },
    maxItemLimit() {
      return this.selected.length > 50
    },
    itemsToDeselect() {
      return this.selected.length - 50
    },
    selectedUpcs() {
      if (this.selected.length > 0) {
        return this.selected.map(item => item.upc)
      }
      return []
    },
    minDate() {
      if (this.ad) {
        const adDate = utcToZonedTime(new Date(this.ad.ad_date), 'UTC')
        return format(subWeeks(adDate, 53), 'yyyy-MM-dd')
      }
      return null
    },
    maxDate() {
      if (this.ad) {
        const adDate = utcToZonedTime(new Date(this.ad.ad_date), 'UTC')
        return format(subWeeks(adDate, 4), 'yyyy-MM-dd')
      }
      return null
    },
    initDateRange() {
      const minDate = this.minDate
      const maxDate = format(
        addMonths(utcToZonedTime(new Date(this.minDate), 'UTC'), 6),
        'yyyy-MM-dd'
      )
      return {
        min: minDate,
        max: maxDate
      }
    },
    formattedDateRange() {
      const start = format(
        utcToZonedTime(new Date(this.initDateRange.min), 'UTC'),
        'M/d/yyyy'
      )
      const end = format(
        utcToZonedTime(new Date(this.initDateRange.max), 'UTC'),
        'M/d/yyyy'
      )
      if (this.dateRange.length > 1) {
        const [startDate, endDate] = this.dateRange
        const startFormatted = format(
          utcToZonedTime(new Date(startDate), 'UTC'),
          'M/d/yyyy'
        )
        const endFormatted = format(
          utcToZonedTime(new Date(endDate), 'UTC'),
          'M/d/yyyy'
        )
        return `${startFormatted} - ${endFormatted}`
      }
      return `${start} - ${end}`
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        if (this.ad) {
          this.queryData(this.minDate, this.maxDate)
        }
      } else {
        this.resetChart()
      }
    },
    dateRange(newValue) {
      if (newValue.length > 1) {
        const [startDate, endDate] = newValue
        this.chartOptions.scales.x.min = startDate
        this.chartOptions.scales.x.max = endDate
      }
    }
  },
  mounted() {
    this.width = window.innerWidth - 250
  },
  methods: {
    confirmUpdate() {
      this.options.data = this.dateRange
      this.$emit('confirmUpdate', this.options)
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[2].data = []
    },
    async queryData(start, end) {
      this.loading = true
      try {
        const payload = {
          store: this.store.id,
          start_date: start,
          end_date: end,
          upcs: this.selectedUpcs
        }
        const response = await OrderGrid.queryData(payload)
        let count = response.data.length
        let message = 'Results Found'
        this.options = {
          icon:
            response.data.length > 0 ? 'mdi-calendar-question' : 'mdi-magnify',
          title:
            response.data.length > 0
              ? 'Are you sure?'
              : 'No Data Available For Selected Product',
          message:
            response.data.length > 0
              ? `Confirming the changes will overwrite the existing data.<br />
        Please click <b>Confirm</b> to proceed with update.`
              : `${count} ${message}`,
          event:
            response.data.length > 0
              ? 'updateSuggestedOrder'
              : 'noItemsToDisplay'
        }
        this.showDialog = true

        if (response.data.length > 0) {
          const results = response.data

          this.chartOptions.scales.x.min = this.initDateRange.min
          this.chartOptions.scales.x.max = this.initDateRange.max

          results.map(data => {
            for (const key in data) {
              if (key == 'total_quantity') {
                this.chartData.datasets[0].data.push(data[key])
              }
              if (key == 'total_sales') {
                this.chartData.datasets[1].data.push(data[key])
              }
              if (key == 'avg_price_point') {
                this.chartData.datasets[2].data.push(data[key])
              }
              if (key == 'transaction_date') {
                this.chartData.labels.push(data[key])
              }
            }
          })
          const totalQuantity = this.chartData.datasets[0].data
          const totalSales = this.chartData.datasets[1].data
          const avgPricePoint = this.chartData.datasets[2].data

          this.chartOptions.scales.y.max =
            this.getRoundedMaxValue(avgPricePoint)
          this.chartOptions.scales.y2.max =
            this.getRoundedMaxValue(totalQuantity)
          this.chartOptions.scales.y3.max = this.getRoundedMaxValue(totalSales)

          this.setTicksStepSize(
            this.chartOptions.scales.y2.max,
            this.chartOptions.scales.y2
          )
          this.setTicksStepSize(
            this.chartOptions.scales.y3.max,
            this.chartOptions.scales.y3
          )
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    setTicksStepSize(maxValue, scaleOptions) {
      if (maxValue > 100) {
        scaleOptions.ticks.stepSize = 100
      } else if (maxValue > 50) {
        scaleOptions.ticks.stepSize = 50
      } else if (maxValue > 25) {
        scaleOptions.ticks.stepSize = 10
      } else {
        scaleOptions.ticks.stepSize = 5
      }
    },
    roundToClosest(num) {
      const nearestMultiple = factor => Math.ceil(num / factor) * factor
      if (num > 100) {
        return nearestMultiple(100)
      } else if (num > 50) {
        return nearestMultiple(50)
      } else if (num > 10) {
        return nearestMultiple(10)
      } else {
        return nearestMultiple(5)
      }
    },

    getRoundedMaxValue(arrValues) {
      const maxValue = Math.max(...arrValues)
      const roundedMaxValue = this.roundToClosest(maxValue)
      return roundedMaxValue
    },
    cancelChart() {
      if (
        this.chartData.labels.length > 0 ||
        this.chartData.datasets.length > 0
      ) {
        this.chartData.labels = []
        this.chartData.datasets[0].data = []
        this.chartData.datasets[1].data = []
        this.chartData.datasets[2].data = []
      }
      // this.resetChart()
      return this.$emit('update:value', false)
    },
    resetChart() {
      this.dateRange = [this.initDateRange.min, this.initDateRange.max]
    },
    setSuggestedOrderDates(event) {
      const [startDate, endDate] = event
      if (isBefore(new Date(startDate), new Date(endDate))) {
        this.dateRange = [startDate, endDate]
      } else {
        this.dateRange = [endDate, startDate]
      }
    }
  }
}
</script>
<style scoped>
.close:hover {
  color: red;
}
</style>
